@import 'edit-form-response/edit-form-response.less';
@import 'form-builder/form-builder.less';
@import 'view-form/view-form.less';
@import 'view-form-responses/view-form-responses.component.less';
@import 'shared/components/create-form-modal/create-form-modal.component.less';
@import 'shared/components/form-conflicts-modal/form-conflicts-modal.component.less';
@import 'shared/components/form-response/form-response.component.less';
@import 'shared/components/form-response-payment-summary/form-response-payment-summary.component.less';
@import 'shared/components/form-response-people-section/form-response-people-section.component.less';

.all-forms-table {
  tr {
    td:first-child:not(.empty-state) {
      a {
        font-weight: bold;
        color: #000;
      }
    }
  }
}

.form-responses {
  .toolbar {
    position: relative;
    border: 1px solid #d4d4d5;
    border-bottom: none;
    background: #fff;
    padding: 1em;
    border-radius: 0.3em 0.3em 0 0;

    .btn:not(.btn-primary)[disabled] {
      cursor: default;
      background: #e0e1e2 !important;

      &:hover {
        background: #e0e1e2 !important;
        color: rgba(0, 0, 0, 0.6);
        border: 1px solid #e0e2e2 !important;
      }
    }

    @media (max-width: 768px) {
      button {
        i.fa {
          margin: 0;
        }
        .text {
          display: none;
        }
      }
    }
  }
}

.form-responses-table {
  border: 1px solid #d4d4d5;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .table-content-wrapper {
    display: inline-block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    vertical-align: top;
    width: calc(~'100% - 49px');
  }

  .table-firstcol {
    display: inline-block;
    max-width: 46px;
    padding: 0;
    margin-bottom: -5px;
    background-color: white;

    th {
      border-right: 1px solid #ddd;
      &:hover {
        background-color: #f9fafb !important;
        cursor: auto;
      }
    }
    td {
      border-right: 1px solid #ddd;
      cursor: pointer;
      padding: 0;
      a {
        display: block;
        padding: 14px 15px;
        &:hover {
          i {
            opacity: 1 !important;
          }
        }
      }
    }
  }

  table {
    border: none !important;
    margin: 0;

    thead tr th {
      white-space: nowrap;
    }

    tbody tr td {
      white-space: nowrap;
    }
  }
}

.cancel-ticket-container {
  .panel {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    margin-top: 100px;
  }

  .panel-body {
    padding: 3em;
  }

  .footer-logo {
    padding: 3em;
    text-align: center;
  }
}
