/**
 * Full calendar
 */
.fullcalendar-main-content {
  position: relative;

  .splashContent,
  .emptyCalendar {
    z-index: 100;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);

    .close {
      float: right;
      margin: 5px 15px;
    }

    .content {
      text-align: center;
      margin-top: 96px;
    }
  }

  .splashContent {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;

    .processing {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      text-align: center;
      z-index: 1000;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .emptyCalendar {
    border: 1px solid #444;
    top: 25%;
    height: auto;
    width: 100%;

    .content {
      margin-top: 0;
    }
  }
}

#fullCalendar .fc-event-container,
.kw_color_picker {
  .color-0 {
    .event-color(@color-0);
  }
  .color-1 {
    .event-color(@color-1);
  }
  .color-2 {
    .event-color(@color-2);
  }
  .color-3 {
    .event-color(@color-3);
  }

  .color-4 {
    .event-color(@color-4);
  }

  .color-5 {
    .event-color(@color-5);
  }

  .color-6 {
    .event-color(@color-6);
  }

  .color-7 {
    .event-color(@color-7);
  }

  .color-8 {
    .event-color(@color-8);
  }

  .color-9 {
    .event-color(@color-9);
  }

  .color-10 {
    .event-color(@color-10);
  }

  .color-11 {
    .event-color(@color-11);
  }

  .color-12 {
    .event-color(@color-12);
  }

  .color-13 {
    .event-color(@color-13);
  }

  .color-14 {
    .event-color(@color-14);
  }

  .color-15 {
    .event-color(@color-15);
  }

  .color-16 {
    .event-color(@color-16);
  }

  .color-17 {
    .event-color(@color-17);
  }

  .color-18 {
    .event-color(@color-18);
  }

  .color-19 {
    .event-color(@color-19);
  }

  .color-20 {
    .event-color(@color-20);
  }

  .color-21 {
    .event-color(@color-21);
  }

  .color-22 {
    .event-color(@color-22);
  }

  .color-23 {
    .event-color(@color-23);
  }
}

#fullCalendar .fc-content .event_icons {
  position: absolute;
  bottom: 0;
  right: 0;
  i {
    margin-right: 2px;
  }
}

#fullCalendar .fc-icon-x:after {
  content: none;
}

#fullCalendar .fc-resizable .fc-resizer {
  display: block;
}

#fullCalendar .fc-view-container {
  overflow: hidden;
  position: relative;

  .fc-content {
    height: 100%;
  }

  .ps-scrollbar-x-rail,
  .ps-scrollbar-y-rail {
    z-index: 200;
  }

  > .ps-scrollbar-y-rail {
    display: none !important;
  }
}

#fullCalendar .fc-resourceDay-view {
  > table {
    width: 100%;
  }

  .fc-scroller {
    overflow: hidden;
  }

  .fc-time-grid {
    > .fc-bg,
    .fc-slats,
    .fc-content-skeleton {
      z-index: initial;
    }
  }

  .fc-slats {
    .fc-axis {
      position: absolute;
      background-color: #fff;
      z-index: 100;

      + td {
        width: 100%;
        z-index: 1;
      }
    }
  }
}

#fullCalendar .panel,
.qtip .panel {
  margin-bottom: 0 !important;

  .panel-heading {
    .panel-title {
      font-size: 16px;
    }

    .qtip-btns {
      padding: 0px;

      .btn {
        padding: 0px 5px;
        color: #333;
      }

      .btn:last-child {
        padding: 0 0 0 15px;
      }
    }
  }

  .panel-body {
    overflow: scroll;
    max-height: 350px;

    .date,
    .location,
    .contributor,
    .qtip-resources,
    .qtip-users,
    .qtip-internal_note {
      display: inline-flex;
      width: 100%;

      i {
        margin: 5px 15px 10px 0;
        color: #a3a3a3;
      }

      span {
        height: auto;

        strong {
          margin: 0px 2px;
        }
      }
    }

    .qtip-users {
      padding: 10px 0px;

      .bullet img {
        width: 25px;
        height: 25px;
        margin-right: 0px;
      }

      .attendance-status {
        i {
          margin: 5px 5px 5px 15px;
        }

        .inline-block();
        span.label {
          margin-left: 10px;
        }
      }
    }

    .qtip-comment,
    .qtip-users,
    .qtip-resources,
    .contributor,
    .location,
    .qtip-internal_note,
    .qtip-author {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .qtip-internal_note {
      .content {
        min-height: 14px;
        max-height: 54px;
        overflow-y: auto;
        line-height: 1.5;
      }
    }

    h6 {
      display: inline-block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 13px;
      color: #333;
    }

    ul.bg-danger {
      background-color: #f2dede;
      padding: 10px;
      li {
        list-style: none;
      }
    }
  }
}

.btn-group {
  vertical-align: top;
}

#fullCalendar,
.fullcalendarHeader {
  margin: 0;

  .fc-toolbar {
    display: none;
  }

  .fc-header-title {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
  }

  .datepicker button.button {
    vertical-align: top;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;

    &:focus {
      outline: 0;
    }
  }

  span.fc-group-buttons {
    margin-left: 4px;
    span.fc-button {
      margin-left: -4px;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
      }
    }
  }

  .btn-group {
    vertical-align: top;
    text-transform: capitalize;

    a.dropdown-toggle {
      &:focus {
        color: #000000;
        text-decoration: none;
      }
      &:hover {
        color: #000000;
      }
    }
  }

  .fc-button-today {
    margin-right: 0;
  }

  .fc-corner-left,
  div.action-links .fc-corner-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
  }

  .fc-corner-right,
  div.action-links .fc-corner-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
  }

  .fc-header {
    .fc-header-center {
      text-align: center;
      text-transform: capitalize;

      h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: normal;
      }
    }

    .fc-header-right {
      width: 100%;
      text-align: right;

      .btn-group.calendar-views {
        margin-left: 5px;
        margin-right: 7px;

        .btn.btn-default.agendaDay {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          -webkit-border-top-left-radius: 4px;
          -webkit-border-bottom-left-radius: 4px;
        }
      }

      div.action-links {
        display: inline-block;
        height: 29px;
        margin-left: 2px;
      }
    }
  }

  .ui-datepicker-calendar {
    display: none;
  }
}

.fullcalendarHeader {
  margin-bottom: 20px;
  .btn {
    padding: 5px 10px;
  }
  .form-control {
    height: 32px;
  }
  .datepicker.input-group {
    margin-right: 5px;
  }
  input#datepicker-input {
    font-weight: lighter;
  }

  // Responsive fixes
  @media (max-width: 1200px) {
    .fc-header {
      .fc-header-title {
        font-size: 13px;
      }
    }

    .fc-button-today,
    .fc-button-prev,
    .fc-button-next,
    .agendaDay,
    .agendaWeek,
    .month,
    .resources,
    .users,
    .btn,
    #action-dropdown-0 .btn,
    #action-dropdown-1 .btn,
    #action-dropdown-2 .btn,
    .datepicker.input-group .input-group-btn button.btn {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
    }

    .datepicker.input-group {
      width: 120px;

      input.form-control,
      .input-group-addon {
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
}

#fullCalendar {
  background-color: #ffffff;

  /* special only for fullCalendar */
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  /* the new scrollbar will have a flat appearance with the set background color */

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.6);
  }
  /* this will style the thumb, ignoring the track */

  .fc-helper {
    opacity: 0.7;
  }

  .fc-event-container {
    position: relative;
  }

  .fc-event.fc-short {
    padding: 4px;
  }
  .fc-event:not(.fc-short) {
    .fc-time {
      padding: 4px 4px 0 4px;
    }
    .fc-title {
      padding: 0 4px 4px 4px;
    }
    .event_icons {
      font-size: 10px;
    }
  }
  .fc-day-grid-event.fc-event {
    .event_icons {
      padding: 0 !important;
    }
  }

  .fc-popover {
    overflow: auto;
  }
}

#absenceView {
  #absenceView-controller,
  .absenceView-controller {
    background-color: white;
    padding: 5px;
    border: 1px solid #dddddd;

    .fullcalendar-absence-table.fullcalendar-absence-header-table {
      td {
        color: #333;
        font-size: 10px;
        padding: 2px 0;
        border: 1px solid #dddddd;
      }
    }

    .fullcalendar-absence-inner-scroll {
      td {
        height: 16px;
        font-size: 10px;
        overflow: hidden;
        padding: 2px 5px;
        border: 1px solid #dddddd;

        span.label {
          display: block;
          padding: 3px;
          margin: 0;
          float: left;
          width: 14px;
          height: 14px;
          margin-right: 5px;
          &.color-0 {
            background: @color-0;
          }

          &.color-1 {
            background: @color-1;
          }

          &.color-2 {
            background: @color-2;
          }

          &.color-3 {
            background: @color-3;
          }

          &.color-4 {
            background: @color-4;
          }

          &.color-5 {
            background: @color-5;
          }

          &.color-6 {
            background: @color-6;
          }

          &.color-7 {
            background: @color-7;
          }
          &.color-8 {
            background: @color-8;
          }
          &.color-9 {
            background: @color-9;
          }
          &.color-10 {
            background: @color-10;
          }
          &.color-11 {
            background: @color-11;
          }
          &.color-12 {
            background: @color-12;
          }
          &.color-13 {
            background: @color-13;
          }
          &.color-14 {
            background: @color-14;
          }
          &.color-15 {
            background: @color-15;
          }
          &.color-16 {
            background: @color-16;
          }
          &.color-17 {
            background: @color-17;
          }
          &.color-18 {
            background: @color-18;
          }
          &.color-19 {
            background: @color-19;
          }
          &.color-20 {
            background: @color-20;
          }
          &.color-21 {
            background: @color-21;
          }
          &.color-22 {
            background: @color-22;
          }
          &.color-23 {
            background: @color-23;
          }
        }
      }
    }
  }
}

#workplan-controller,
.workplan-controller {
  background-color: white;
  padding: 5px;
  border: 1px solid #dddddd;
  margin-top: 5px;
  .workplan-border {
    border-top: 1px solid #dddddd !important;
    border-left: 0;
    border-right: 0;
    margin-top: 0 !important;
    border-bottom: 0;

    .fullcalendar-workplan-header-table {
      border-bottom: 0;
      td {
        height: 16px;
        font-size: 10px;
        overflow: hidden;
        padding: 0 2px;
        border: 1px solid #dddddd;
        border-top: 0;
      }
    }
  }
}

.calendar-filters {
  form .calendar-filters-check-toggle {
    margin: 0;
  }

  .calendar-filters-check-toggle {
    font-size: 13px;
    line-height: 13px;
    width: 323px;
    z-index: 1;
    button {
      color: #333;
      font-weight: normal;
      border: none;
      outline: none;
      height: 100%;
      margin: 0;
      padding: 12px 0 12px 20px;
      background-color: #f0f0f0;
      text-align: left;
      span {
        font-size: 13px;
        padding-left: 2px;
      }
      i {
        color: #555;
        font-size: 12px;
        width: 13px;
      }
    }
  }
}

#calendar-filters {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: #f0f0f0;
  background-clip: padding-box;
  position: fixed;
  padding: 0 0 6px 0;
  right: -325px;
  width: 330px;
  z-index: 99999;
  top: 0;
  bottom: 0;
  border-left: 5px solid #7ab800;

  .navbar-form {
    margin: 0;
    padding: 0;
    width: 100%;
    .searchfilter {
      border: none;
      border-radius: 0;
      background-color: #fff;
      width: 100%;
      box-sizing: initial;
      padding: 10px 20px;
    }
  }

  .calendar-filters-content {
    overflow: hidden;
    height: 100%;
    background: none;
    border: 0;
    width: 100%;

    .panel-group {
      margin-bottom: 0;
    }

    .panel {
      margin-top: 0;
      box-shadow: none;
    }

    .panel-collapse {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(~'100% - 38px');
      background-color: #f0f0f0;
    }

    .panel-heading {
      border: none;
      border-radius: 0;
      padding: 0 0 0 20px;
      height: 38px;

      .accordion-toggle {
        display: block;

        .text-muted {
          color: #333 !important;
        }
      }

      .filter-panel-title {
        font-size: 16px;
        line-height: 38px;
        font-weight: 400;
      }

      .filter-count-badge {
        font-size: 10px;
        background-color: #ccc;
        min-width: 10px;
        cursor: pointer;
        border-radius: 0.25em;
        width: auto;
        padding: 5px 6px;
        color: #fff;
        float: right;
        margin: 9px;
      }

      .filter-item-add {
        width: 38px;
        height: 38px;
        background-color: #ccc;
        float: right;
        text-align: center;
        transition: background-color 0.3s ease-in-out;
        color: #ffffff;
        line-height: 38px;

        &:hover {
          background-color: #7ab800;
        }
      }
    }

    .panel-body {
      background-color: #f0f0f0;
      padding: 0;
      position: relative;
    }

    .calendar-filters-check-toggle {
      position: absolute;
      right: 0;
      width: 100%;
      background-color: #f0f0f0;
    }

    ul {
      display: block;
      width: 100%;
      padding: 0;
      margin: 38px 0 0;

      li {
        list-style-type: none;
        margin: 5px 5px 5px 20px;
        line-height: 25px;

        .bullet-text {
          .name {
            cursor: pointer;
            font-size: 13px;
            color: #333;
          }

          .bullet {
            margin-right: 5px;
            border-radius: 50%;
          }

          .bullet.bullet-empty {
            border-color: #ccc;
            background-color: transparent;
          }

          .bullet-image {
            margin-right: 5px;
            height: 20px;
            cursor: pointer;

            img {
              width: 20px;
            }

            .no-image {
              width: 20px;
              height: 20px;
              background-color: #ccc;
              display: inline-block;
              vertical-align: middle;
              line-height: 20px;
            }
          }

          .bullet-image.bullet-empty {
            img {
              opacity: 0.4;
              filter: alpha(opacity=40); /* For IE8 and earlier */
            }
          }

          .bullet-image.bullet-full {
            img {
              opacity: 1;
              filter: alpha(opacity=100); /* For IE8 and earlier */
            }
          }

          .filter-actions {
            display: none;
            float: right;
          }

          &:hover {
            .filter-actions {
              display: block;

              a:hover {
                i {
                  color: #333;
                }
              }
            }
          }
        }

        .bullet {
          width: 20px;
          height: 20px;
          display: block;
          float: left;
          cursor: pointer;
          border-color: transparent;

          + .name {
            cursor: pointer;
            padding-left: 5px;
            font-weight: normal;
            font-size: 13px;
            color: #333;
            line-height: 150%;
            vertical-align: top;
          }
        }

        .bullet.bullet-full,
        .bullet-image.bullet-full {
          + .name {
            font-weight: 700;
          }
        }
      }
    }
  }

  .toggle {
    @border-radius: 4px;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -webkit-border-radius: @border-radius @border-radius 0 0;
    -moz-border-radius: @border-radius @border-radius 0 0;
    border-radius: @border-radius @border-radius 0 0;
    background-color: #7ab800;
    line-height: 40px;
    text-align: center;
    position: absolute;
    font-size: 20px;
    cursor: pointer;
    color: #ffffff;
    height: 40px;
    width: 70px;
    left: -55px;
    top: 50%;
    font-weight: bold;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    &:hover {
      left: -60px;
    }
  }

  &.active:before,
  &:hover:before {
    left: -35px;
  }

  &.active {
    box-sizing: content-box;
    right: 0;
  }
}

@media (max-width: 1000px) {
  #calendar-filters {
    &:before {
      @size: 32px;
      font-size: 12px;
      height: @size;
      width: 20px;
      left: -20px;
      line-height: 34px;
    }

    &.active:before,
    &:hover:before {
      left: -25px;
    }
  }
}

span.bullet-full {
  &.color-0 {
    background-color: @color-0;
  }
  &.color-1 {
    background-color: @color-1;
  }
  &.color-2 {
    background-color: @color-2;
  }
  &.color-3 {
    background-color: @color-3;
  }
  &.color-4 {
    background-color: @color-4;
  }
  &.color-5 {
    background-color: @color-5;
  }
  &.color-6 {
    background-color: @color-6;
  }
  &.color-7 {
    background-color: @color-7;
  }
  &.color-8 {
    background-color: @color-8;
  }
  &.color-9 {
    background-color: @color-9;
  }
  &.color-10 {
    background-color: @color-10;
  }
  &.color-11 {
    background-color: @color-11;
  }
  &.color-12 {
    background-color: @color-12;
  }
  &.color-13 {
    background-color: @color-13;
  }
  &.color-14 {
    background-color: @color-14;
  }
  &.color-15 {
    background-color: @color-15;
  }
  &.color-16 {
    background-color: @color-16;
  }
  &.color-17 {
    background-color: @color-17;
  }
  &.color-18 {
    background-color: @color-18;
  }
  &.color-19 {
    background-color: @color-19;
  }
  &.color-20 {
    background-color: @color-20;
  }
  &.color-21 {
    background-color: @color-21;
  }
  &.color-22 {
    background-color: @color-22;
  }
  &.color-23 {
    background-color: @color-23;
  }
}

span.bullet-empty {
  &.color-0 {
    border: 1px solid @color-0;
  }
  &.color-1 {
    border: 1px solid @color-1;
  }
  &.color-2 {
    border: 1px solid @color-2;
  }
  &.color-3 {
    border: 1px solid @color-3;
  }
  &.color-4 {
    border: 1px solid @color-4;
  }
  &.color-5 {
    border: 1px solid @color-5;
  }
  &.color-6 {
    border: 1px solid @color-6;
  }
  &.color-7 {
    border: 1px solid @color-7;
  }
  &.color-8 {
    border: 1px solid @color-8;
  }
  &.color-9 {
    border: 1px solid @color-9;
  }
  &.color-10 {
    border: 1px solid @color-10;
  }
  &.color-11 {
    border: 1px solid @color-11;
  }
  &.color-12 {
    border: 1px solid @color-12;
  }
  &.color-13 {
    border: 1px solid @color-13;
  }
  &.color-14 {
    border: 1px solid @color-14;
  }
  &.color-15 {
    border: 1px solid @color-15;
  }
  &.color-16 {
    border: 1px solid @color-16;
  }
  &.color-17 {
    border: 1px solid @color-17;
  }
  &.color-18 {
    border: 1px solid @color-18;
  }
  &.color-19 {
    border: 1px solid @color-19;
  }
  &.color-20 {
    border: 1px solid @color-20;
  }
  &.color-21 {
    border: 1px solid @color-21;
  }
  &.color-22 {
    border: 1px solid @color-22;
  }
  &.color-23 {
    border: 1px solid @color-23;
  }
}

.fc-daygrid-dot-event {
  padding: 0px;
}

.fc-daygrid-dot-event:hover {
  background-color: unset;
  transform: translateY(-2px);
}

.fc-event:hover {
  transform: translateY(-2px);
}

.fc-event {
  border-radius: 0 !important;
  border: none;
  background-color: transparent;

  /**
    * A pulsating element added to a calendar event when it is clicked
    * in order to visualize that the event is being loaded in the background
    */
  @keyframes pulsateOpacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }

  .fc-event-pulser {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    animation-name: pulsateOpacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.fc .fc-more-popover .fc-popover-body {
  min-width: 0 !important;
}

// To reduce from default 9999 so it is shown below modals.
.fc-popover {
  z-index: 999 !important;
}

div.col-lg-10 {
  padding: 0;
}

@media print {
  .lockbutton,
  .btn-group,
  .pull-right,
  .help-text,
  .info-text,
  button.pull-right {
    display: none;
    overflow: hidden;
  }
}

.modal-footer a.pull-left {
  i.icon-thrash {
    display: inline-block;
  }
  div.remove-text-holder {
    display: inline-block;
    margin-left: 2px;
  }
}

.nav > li#kw-workplan--user-select > a {
  margin: 0 0 25px 0;
  padding: 0 0;
}

.nav > li#kw-workplan--user-select > a:hover,
.nav > li#kw-workplan--user-select > a:focus {
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border-color: #428bca;
}

.worktype-container {
  border: solid transparent 1px;
  border-radius: 2px;
  border-bottom: 0;

  .worktype-list {
    cursor: inherit;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;
    overflow: hidden;
  }
  .badge {
    float: right;
    font-size: 11px;
    min-width: 26px;
    width: auto;
    height: 26px;
    border-radius: 100px;
    padding: 7px;
  }

  .lockbutton {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    .lockbutton-inner {
      padding: 10px;

      i {
        font-size: 20px;
        display: block;
        margin-top: 5px;
      }

      .btn:hover,
      .btn:focus {
        color: white;
      }
    }
  }
}

#datepicker.btn {
  margin-left: 0 !important;
  margin-bottom: 0;
}

body.page-app-work-plan #main-content .block-page-content {
  padding: 15px 0;
}

.workplan-print-title {
  font-size: 20px;
  display: none;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
}

/* Workplan overview*/

@media (max-width: 992px) {
  .workplan_search {
    margin: 10px 0 0 0;
  }
}

div.workplan_search {
  float: right;

  .input-group input {
    font-weight: lighter;
  }
}

select.createuid {
  display: inline-block;
  width: auto;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  font-weight: lighter;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.actions-header {
  margin: 0 -15px;
}

table.workplan-overview-table {
  margin-top: 20px;

  .label {
    background-color: #999999;
    margin-right: 5px;
    margin-bottom: 5px;
    transition: all 0.1s ease-in-out;
    font-size: 12px;
    font-weight: lighter;
    display: inline-block;

    a {
      color: white;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.filter-actions .btn.btn-mini {
  padding: 0;
}

ul.resourceView,
div.fc-widget-header {
  .color-0 {
    .resource-color(@color-0);
  }
  .color-1 {
    .resource-color(@color-1);
  }
  .color-2 {
    .resource-color(@color-2);
  }
  .color-3 {
    .resource-color(@color-3);
  }
  .color-4 {
    .resource-color(@color-4);
  }
  .color-5 {
    .resource-color(@color-5);
  }
  .color-6 {
    .resource-color(@color-6);
  }
  .color-7 {
    .resource-color(@color-7);
  }
  .color-8 {
    .resource-color(@color-8);
  }
  .color-9 {
    .resource-color(@color-9);
  }
  .color-10 {
    .resource-color(@color-10);
  }
  .color-11 {
    .resource-color(@color-11);
  }
  .color-12 {
    .resource-color(@color-12);
  }
  .color-13 {
    .resource-color(@color-13);
  }
  .color-14 {
    .resource-color(@color-14);
  }
  .color-15 {
    .resource-color(@color-15);
  }
  .color-16 {
    .resource-color(@color-16);
  }
  .color-17 {
    .resource-color(@color-17);
  }
  .color-18 {
    .resource-color(@color-18);
  }
  .color-19 {
    .resource-color(@color-19);
  }
  .color-20 {
    .resource-color(@color-20);
  }
  .color-21 {
    .resource-color(@color-21);
  }
  .color-22 {
    .resource-color(@color-22);
  }
  .color-23 {
    .resource-color(@color-23);
  }
}

.panel-body {
  span.bullet.color-0 {
    border: 1px solid @color-0;
    background-color: @color-0;
  }

  span.bullet.color-1 {
    border: 1px solid @color-1;
    background-color: @color-1;
  }

  span.bullet.color-2 {
    border: 1px solid @color-2;
    background-color: @color-2;
  }

  span.bullet.color-3 {
    border: 1px solid @color-3;
    background-color: @color-3;
  }

  span.bullet.color-4 {
    border: 1px solid @color-4;
    background-color: @color-4;
  }

  span.bullet.color-5 {
    border: 1px solid @color-5;
    background-color: @color-5;
  }

  span.bullet.color-6 {
    border: 1px solid @color-6;
    background-color: @color-6;
  }

  span.bullet.color-7 {
    border: 1px solid @color-7;
    background-color: @color-7;
  }

  span.bullet.color-8 {
    border: 1px solid @color-8;
    background-color: @color-8;
  }

  span.bullet.color-9 {
    border: 1px solid @color-9;
    background-color: @color-9;
  }

  span.bullet.color-10 {
    border: 1px solid @color-10;
    background-color: @color-10;
  }

  span.bullet.color-11 {
    border: 1px solid @color-11;
    background-color: @color-11;
  }

  span.bullet.color-12 {
    border: 1px solid @color-12;
    background-color: @color-12;
  }

  span.bullet.color-13 {
    border: 1px solid @color-13;
    background-color: @color-13;
  }

  span.bullet.color-14 {
    border: 1px solid @color-14;
    background-color: @color-14;
  }

  span.bullet.color-15 {
    border: 1px solid @color-15;
    background-color: @color-15;
  }

  span.bullet.color-16 {
    border: 1px solid @color-16;
    background-color: @color-16;
  }

  span.bullet.color-17 {
    border: 1px solid @color-17;
    background-color: @color-17;
  }

  span.bullet.color-18 {
    border: 1px solid @color-18;
    background-color: @color-18;
  }

  span.bullet.color-19 {
    border: 1px solid @color-19;
    background-color: @color-19;
  }

  span.bullet.color-20 {
    border: 1px solid @color-10;
    background-color: @color-10;
  }

  span.bullet.color-21 {
    border: 1px solid @color-21;
    background-color: @color-21;
  }

  span.bullet.color-22 {
    border: 1px solid @color-22;
    background-color: @color-22;
  }

  span.bullet.color-23 {
    border: 1px solid @color-23;
    background-color: @color-23;
  }
}

ul.resourceView {
  width: 100%;
  text-align: center;
  margin: 0 !important;
  display: inline-block;
  height: 16px;
  float: left;
  overflow: hidden;
  padding: 0;
  font-size: 11px;
  li {
    display: inline-block;
    float: left;
    border-right: 0 !important;
    padding: 0;
    margin: 0;
    margin-left: -1px;
    border-left: 1px solid #ffffff !important;
  }
  li:first-child {
    border-left: 0 !important;
    margin-left: 0;
  }
}

//Workplan

div.col-lg-10 {
  padding: 0;
}

.panel-workplan {
  margin: 0;

  .panel-body {
    padding: 0;
  }

  thead {
    td {
      text-align: center;
      font-size: 12px;

      .day {
        font-weight: bold;
      }

      &.weekend {
        background-color: #f5f5f5;

        &:hover {
          background-color: #f5f5f5 !important;
          cursor: inherit;
        }
      }

      &:hover {
        background-color: inherit !important;
        cursor: inherit;
      }
    }
  }

  tbody {
    td {
      .slot-time-label {
        padding: 5px;
      }

      &.ui-selected,
      &.ui-selecting,
      &:hover {
        background-color: lightblue !important;
        cursor: pointer;
      }

      &:first-child {
        width: 1%;

        &:hover {
          background-color: #fff !important;
          cursor: inherit;
        }
      }
    }
  }
}

#workplan-table {
  clear: both;
  min-height: 20px;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  #kw-workplan--user-select .btn {
    white-space: normal;
  }

  .image {
    margin-right: 10px;
  }

  .no-results {
    td {
      border: 1px solid #fff;
      background-color: #fff;

      .text {
        padding: 30px 0 0 0;
      }
      &:hover {
        background-color: #fff !important;
      }
    }
  }

  table.animated.fadeIn {
    background-color: white;
    border: 0;
  }

  div.span10 {
    margin-left: 10px;
  }

  .col-lg-12 {
    padding: 15px;
    border-left: 1px solid #dddddd;
    background-color: white;
    border-radius: 0 0 4px 0;
  }

  .col-lg-12.datepicker-navigator {
    padding-bottom: 0 !important;
    border-radius: 0 4px 0 0;
  }

  .picture {
    img {
      max-width: 40px;
      height: auto;
    }

    i {
      font-size: 30px;
    }
  }

  .help-text {
    opacity: 0.7;
    text-align: center;
    margin-top: 10px;
  }

  .chzn-container {
    vertical-align: middle;
  }
  select {
    margin-bottom: 0;
  }

  .worktype {
    padding: 15px;

    .info-text {
      margin-top: 15px;
      text-align: center;
    }
  }
}

.modal-footer a.pull-left {
  i.icon-thrash {
    display: inline-block;
  }
  div.remove-text-holder {
    display: inline-block;
    margin-left: 2px;
  }
}

.nav > li#kw-workplan--user-select > a {
  margin: 0 0 25px 0;
  padding: 0 0;
}

.nav > li#kw-workplan--user-select > a:hover,
.nav > li#kw-workplan--user-select > a:focus {
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border-color: #428bca;
}

.modal-content {
  .modal-header {
    h4 {
      margin: 0;
    }
  }

  .modal-body {
    .worktype-container {
      margin: auto;
      width: 100%;

      .worktype-list {
        display: inline-block;
        width: 50%;
        overflow: hidden;
        padding: 0;
        border-bottom: 0 solid #dddddd;

        h4 {
          padding: 15px;
          font-size: 11px;
          font-weight: bold;
          vertical-align: middle;
          margin: 0;
          color: white;
          -moz-transition: all 0.25s ease-out;
          -webkit-transition: all 0.25s ease-out;
          -o-transition: all 0.25s ease-out;
          transition: all 0.25s ease-out;
          opacity: 1;
          filter: alpha(opacity=100);

          i {
            float: right;
            width: 12px;
          }

          span {
            width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }

          &.label.active {
            opacity: 1;
            filter: alpha(opacity=100);
          }
          &:hover {
            opacity: 0.6 !important;
            filter: alpha(opacity=60) !important;
          }
        }
      }
    }
  }
}

#datepicker.btn {
  margin-left: 0 !important;
  margin-bottom: 0;
}

.datepicker {
  outline: 0;
  max-width: 136px;
  float: left;

  .form-control {
    float: left;
  }

  table {
    border-radius: 0;
    padding: 5px;
    display: block;

    .active {
      span.text-info {
        color: white;
      }
    }

    button.btn {
      border-radius: 0;
      font-size: 13px;
      &:focus {
        outline: 0;
      }
    }
    &:focus {
      outline: 0;
    }
  }

  ul.dropdown-menu {
    width: auto;
    outline: 0;

    .btn-sm {
      border-radius: 0;
      width: 100%;
      .text-info {
        color: #333333;
        font-weight: bold;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.worktypes-left-side {
  line-height: 150%;
  position: relative;
  display: block;
  overflow: hidden;
  width: 75%;
  font-weight: normal;
  font-size: 13px;
  padding: 3px 0;
}

body.page-app-work-plan #main-content .block-page-content {
  padding: 15px 0;
}

.workplan-print-title {
  font-size: 20px;
  display: none;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
}

#workplan.hidden {
  display: none;
}

#workplan ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 8px;
}

#workplan ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #969696;
}

#absenceView ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 8px;
}

#absenceView ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #969696;
}

#workplan {
  /*Styling for the workplan overview in the full calendar */
  @border-color: #dddddd;
  .workplan-border {
    border: 1px solid @border-color;
    border-left: 0;
    border-right: 0;
    margin-top: 5px;
  }
  overflow: hidden;
  position: relative;
  .fullcalendar-workplan-header-table {
    border: 1px solid @border-color;
    border-top: 0;
    font-size: 10px;
    border-bottom: 2px solid @border-color;
    td {
      text-align: center;
      font-weight: bold;
      div {
        text-align: left;
      }
    }
  }
  .fullcalendar-workplan-table {
    table-layout: fixed;
    font-size: 11px;
    td {
      height: 16px;
      overflow: hidden;
      padding: 0;
      border: 1px solid #dddddd;
      border-top: 0;
      font-size: 10px;
    }
  }
  .fullcalendar-workplan-inner-scroll {
    max-height: 65px;
    overflow-y: hidden;

    .fullcalendar-workplan-inner-table {
      border: 1px solid #dddddd;
      border-top: 0;
      border-bottom: 0;

      td {
        height: 16px;
        overflow: hidden;
        padding: 0;
        border: 1px solid #dddddd;
        border-top: 0;
        font-size: 10px;

        > div {
          padding-left: 5px;
        }
      }
    }
  }
  .workTypes-table {
    margin-top: 5px;
    overflow: hidden;
    div {
      float: left;
      overflow: hidden;
      margin-right: 2px;
      span {
        font-size: 11px;
        padding: 2px 4px;
        display: block;
        color: white;
      }
    }
  }
}

#absenceView {
  /*Styling for the workplab overview in the full calendar */
  @border-color: #636363;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
  .fullcalendar-absence-header-table {
    border: 1px solid @border-color;
    border-top: 0;
    font-size: 10px;
    border-bottom: 0;
    td {
      text-align: center;
      font-weight: bold;
      border-bottom: 0;
    }
  }
  .fullcalendar-absence-table {
    table-layout: fixed;
    font-size: 11px;
    td {
      height: 16px;
      overflow: hidden;
      padding: 0 0 0 5px;
      border: 1px solid @border-color;
    }
  }
  .fullcalendar-absence-inner-scroll {
    max-height: 75px;
    overflow-y: hidden;
    .fullcalendar-absence-inner-table {
      margin-top: -1px;
      border: 1px solid @border-color;
      border-top: 0;
      border-bottom: 0;
    }
  }
}

.absence-list-title {
  background-color: #cccccc;
  font-weight: bold;
  padding: 3px 5px;
  display: block;
  width: 100%;
}

.absence-list-div {
  height: auto !important;
}

.absence-list-content {
  background-color: #fff;
  border: 1px solid #ccc;
  height: auto !important;
}

.absence-list-content table {
  width: 100%;
}

.perfect-scrollbar {
  position: relative;
  overflow: hidden;
  .ps-scrollbar-y-rail {
    right: 1px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    width: 7px !important;
    .ps-scrollbar-y {
      right: 0 !important;
      border-radius: 0 !important;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      width: 7px !important;
    }
  }
}

// Apply the same space between the line and name of nationalHolidays like regular events in fullcalendar
.fc-annotation {
  .fc-content {
    .fc-title {
      padding: 0 4px 4px 4px;
      color: #fff;
    }
  }

  &.holiday {
    .fc-content {
      .fc-title {
        padding: 0 4px;
        color: inherit;
      }
    }
  }
}

#absenceView .perfect-scrollbar {
  max-height: 65px;
}

#absenceView,
#workplan {
  .perfect-scrollbar {
    max-height: 65px;
  }
}

.workplan-qtip {
  background-color: black;
  border: 1px solid black;
  color: white;
}

.fc-more-popover {
  max-height: 500px;
  overflow-y: scroll;
}

// Better illumination color for the current date of the fullcalendar
.fc-view-container {
  // Day, Week, Resources, Users
  td.fc-widget-content.fc-today {
    background-color: #eaeaea;
  }
  // Month
  .fc-month-view {
    td.fc-widget-content.fc-today {
      height: 100%;
    }
    td.fc-today {
      font-weight: bold;
    }
  }
}

@media (max-width: 1200px) {
  #workplan-table {
    .col-lg-10 {
      margin: 30px 30px 15px 30px;

      .col-lg-12 {
        border-left: 0;
        padding: 15px 15px 0 15px;
      }
    }
  }
}

// Fix for long e-mail address in fullcalendar user view.
.fc th {
  vertical-align: middle;
  line-height: initial;
  padding: 3px;
  font-size: 13px;
  word-wrap: break-word;
}

.fc-event-inner {
  -webkit-print-color-adjust: exact;
  position: relative;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 0;
  overflow: hidden;
}

// Full calendar
#fullCalendar,
.fullcalendarHeader {
  .btn {
    margin: 0;
  }

  .fc-button-today {
    margin-right: 2px !important;
  }

  .datepicker.input-group {
    margin-right: 5px;
    max-width: 180px;
  }

  .fc-header .fc-header-right .btn-group.calendar-views {
    margin: 0 2px 0 5px;

    .btn {
      margin: 0;
    }
  }
}

// Calendar filter
#calendar-filters {
  box-shadow: 0px 1px 22px 0 rgba(34, 36, 38, 0.15);

  .calendar-filters-content {
    .tab-header .title,
    .ui-accordion-content-active ul li {
      .bullet-text .filter-actions a.btn.btn-mini {
        padding: 1px 4px !important;
        height: auto;
        background: transparent !important;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.45 !important;

        &:hover {
          opacity: 1 !important;
        }
      }
    }
  }
}

.calendar {
  .list-group-item {
    padding: 15px 15px;
    font-size: 13px;

    .event-img {
      width: 100%;
      height: auto;
    }

    .media {
      margin-top: 5px;

      &:first-child {
        margin-top: inherit;
      }

      .media-left {
        display: table-cell;
        vertical-align: top;
        padding-right: 10px;
      }

      .media-body {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
      }
    }

    label {
      margin: 0;
    }

    .fa-circle {
      font-size: 1em;
      padding-right: 5px;
    }

    // Comments.
    .comment-header {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .comment-name,
    .comment-time {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  .booking {
    @media (min-width: 1200px) {
      .col-md-2 {
        width: 25%;
      }

      .col-md-10 {
        width: 75%;
      }
    }
  }

  textarea {
    resize: vertical;
  }

  // Timeline component.
  .timeline-centered {
    .timeline-icon.not-sent {
      color: #999;
      background: #e1e1e1;
    }

    .label {
      float: left;
      display: block;
      clear: both;
      margin-top: 5px;
      width: 45px;
      margin-left: -2px;
      color: #999;
      background: white;
      white-space: normal;
      padding: 0.6em 0;
      text-transform: uppercase;
    }

    .media-details > span {
      display: inline-block;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
}

#print-calendar {
  #print-calendar__header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    #print-calendar__header-buttons {
      justify-content: end;

      button {
        margin-left: 10px;
        margin-bottom: 5px;
      }
    }
    #print-calendar__header-title h4 {
      white-space: nowrap;
    }
  }
  #print-calendar__records {
    display: flex;
    flex-direction: column;
    #print-calendar__records__topBar {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 15px;
      padding-bottom: 0px !important;

      #print-calendar__records__search {
        display: flex;
        justify-content: center;

        #print-calendar__records__search__input {
          width: 450px;
          @media (max-width: 768px) {
            width: 250px;
          }
          @media (max-width: 425px) {
            width: 150px;
          }
          margin-right: 10px;
          margin-bottom: 10px;
        }
        a {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }

      #print-calendar__records__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        cd-dropdown-filter a {
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
    }

    #print-calendar__records__resultsBar {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }
    #print-calendar__records__table-wrapper {
      overflow: scroll;

      .table tbody > tr > td {
        vertical-align: top !important;
        padding: 0.5em;

        .media .media-object {
          display: flex;
          align-items: baseline;

          .fa-circle {
            margin-right: 5px;
          }
          span {
            white-space: nowrap;
          }
        }
      }
      .table thead > tr > th {
        padding: 0.5em;

        a {
          display: flex;
          align-items: baseline;
        }
      }
    }
  }
}
